import { AlphaCard, Box, Inline, Text } from "@shopify/polaris";
import { useIntl } from "react-intl";

export const InvalidOrderCard = () => {
  const { formatMessage } = useIntl();

  return (
    <Inline align="center">
      <Box maxWidth="680px" padding="5">
        <AlphaCard>
          <Text as="h1" variant="headingXl">
            {formatMessage({ defaultMessage: "Whoops!", id: "tracking.order.invalid_header" })}
          </Text>
          <Text as="span" variant="bodyLg">
            {formatMessage({
              defaultMessage:
                "We can't seem to find what you're looking for. You can find the tracking link in your order summary, or contact us.",
              id: "tracking.order.invalid_subheader",
            })}
          </Text>
        </AlphaCard>
      </Box>
    </Inline>
  );
};
